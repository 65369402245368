@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
        @apply bg-[#ecf0f3] text-[#1f2937] tracking-wide;
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-bold;
    }

    h1 {
        @apply text-4xl sm:text-5xl md:text-6xl font-[Raleway];
    }

    h2 {
        @apply text-3xl sm:text-4xl;
    }

    button {
        @apply shadow-xl shadow-gray-400 rounded-xl uppercase bg-gradient-to-r from-[#5651e5] to-[#709dff] text-white;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)   
    )!important;
}


